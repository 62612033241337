import { Intent } from "@blueprintjs/core";
import React, { ErrorInfo } from "react";
import { AppToaster } from "./AppToaster";

export class ErrorBoundary extends React.Component {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    AppToaster.show({
      message: error.toString(),
      intent: Intent.DANGER,
    });
  }

  render() {
    return this.props.children;
  }
}
