import { Navbar } from "@blueprintjs/core";
import { useLocation } from "react-router-dom";

export function PageTitle() {
  const location = useLocation();
  //const selectedEvent = useSelector((state: any) => state.selectedEvent.event);

  // if (selectedEvent) {
  //     return (
  //         <SelectedEventTitle eventName={selectedEvent.name} eventDate={selectedEvent.date} venueName={selectedEvent.venue.name} city={selectedEvent.venue.city} region={selectedEvent.venue.region} />
  //     );
  // }

  let title = "";

  if (location.pathname.toLowerCase().startsWith("/purchases")) {
    title = "Purchases";
  }

  if (title === "") {
    return null;
  }

  return (
    <>
      <Navbar.Divider />
      <Navbar.Heading>{title}</Navbar.Heading>
    </>
  );
}
