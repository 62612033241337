import { Classes, Icon } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { parse } from "date-fns";
import { IEventSummary } from "../../models/IEventSummary";
import { longEventDateTimeFormatter } from "../../utilities/Formatters";

export interface SelectedEventTitleProps {
  event?: IEventSummary;
}

export function SelectedEventTitle({ event }: SelectedEventTitleProps) {
  if (!event) {
    return null;
  }

  const areas = `
name name
date venue
`;

  const eventDateTime = event
    ? longEventDateTimeFormatter(
        parse(
          event?.date + " " + event?.time,
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        )
      )
    : "";


  const metadata = event ? JSON.parse(event?.metadata) : {};

  return (
    <Composition
      areas={areas}
      gap="4px"
      paddingLeft="4px"
      templateCols="auto 1fr"
    >
      {({ Name, Date, Venue }) => (
        <>
          <Name>
            {event?.vendor === "Ticketmaster" && (
              <a
                href={`https://ticketmaster.com/event/${event.vendorEventId}`}
                target="_blank"
                rel="noreferrer"
              >
                <strong>{event.name}</strong>
              </a>
            )}
            {event?.vendor === "AXS" && (
              <a
                href={`https://www.axs.com/events/${event.vendorEventId}`}
                target="_blank"
                rel="noreferrer"
              >
                <strong>{event.name}</strong>
              </a>
            )}
            {event?.vendor === "SeatGeek" && (
              <a
                href={`https://seatgeek.com/e/events/${event.vendorEventId}`}
                target="_blank"
                rel="noreferrer"
              >
                <strong>{event.name}</strong>
              </a>
            )}
            {event?.vendor === "Evenue" && (
                <a
                    href={`${metadata.url}`}
                    target="_blank"
                    rel="noreferrer"
                >
                  <strong>{event.name}</strong>
                </a>
            )}
            {event?.vendor !== "Ticketmaster" && event?.vendor !== "AXS" && event?.vendor !== "SeatGeek" && event?.vendor !== "Evenue" && <strong>{event.name}</strong>}
          </Name>
          <Date>
            <div className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
              <Icon icon="calendar" style={{ paddingRight: "4px" }} />
              {eventDateTime}
            </div>
          </Date>
          <Venue>
            <div className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
              <Icon icon="map-marker" style={{ paddingRight: "4px" }} />
              {`${event.venueName} - ${event.city ?? ""}, ${event.state ?? ""}`}
            </div>
          </Venue>
        </>
      )}
    </Composition>
  );
}
