import { Colors, Icon } from "@blueprintjs/core";
import { ICellRendererParams } from "ag-grid-community";

// Cell renderer that displays a tag icon to the left of the cell value
export function CellRendererTags(params: ICellRendererParams) {
  const { valueFormatted } = params;

  if (valueFormatted === "") {
    return <span />;
  }

  return (
    <span>
      <Icon icon="tag" color={Colors.GRAY3} /> {valueFormatted}
    </span>
  );
}
