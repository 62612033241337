import {Icon, Tooltip} from "@blueprintjs/core";
import { ICellRendererParams } from "ag-grid-community";
import {format} from "date-fns";

export function CellRendererExpiration(props: ICellRendererParams) {
  const { value } = props;
  
    if (value === null)
      return null;
    
    const date = new Date(value);
    
    if (date.getTime() < Date.now())
      return null;
    
    return <Tooltip content={"Expires at " + format(new Date(value), "M/d/yyyy h:mm a")}><Icon icon="stopwatch" /></Tooltip>;
}
