import { Composition } from "atomic-layout";
import { Button, ControlGroup, InputGroup, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useState } from "react";
import { useMutation } from "react-query";
import { addOfferCode, removeOfferCode } from "../../directorApi";

export interface CrawlerOfferCodesProps {
  eventId: string;
  vendor: string;
  offerCodes: string[];
  onChange: (offerCodes: string[]) => void;
}

export function CrawlerOfferCodes(props: CrawlerOfferCodesProps) {
  const { offerCodes, onChange, vendor, eventId } = props;
  const [codeInput, setCodeInput] = useState("");

  const addMutation = useMutation((code: string) => {
    if (code !== "") {
      return addOfferCode(vendor, eventId, code).then(() => {
        const newOfferCodes = [...offerCodes, code];
        onChange(newOfferCodes);
        setCodeInput("");
      });
    } else {
      return Promise.resolve();
    }
  });

  const removeMutation = useMutation((code: string) => {
    if (code !== "") {
      return removeOfferCode(vendor, eventId, code).then(() => {
        const newOfferCodes = offerCodes.filter((c) => c !== code);
        onChange(newOfferCodes);
      });
    } else {
      return Promise.resolve();
    }
  });

  const areas = `
    input
    tags
  `;

  return (
    <Composition areas={areas} gap={"1rem"}>
      {({ Input, Tags }) => (
        <>
          <Input>
            <ControlGroup>
              <InputGroup
                leftIcon={IconNames.LOCK}
                value={codeInput}
                disabled={addMutation.isLoading}
                autoFocus={true}
                placeholder={"Enter offer code"}
                onChange={(e) => setCodeInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    addMutation.mutate(codeInput ?? "");
                  }
                }}
              />
              <Button
                icon={IconNames.ADD}
                text={"Add"}
                onClick={() => addMutation.mutate(codeInput ?? "")}
                loading={addMutation.isLoading}
              />
            </ControlGroup>
          </Input>
          <Tags flex={true} style={{ gap: "4px" }}>
            {offerCodes.map((code, index) => (
              <Tag key={index} onRemove={() => removeMutation.mutate(code)}>
                {code}
              </Tag>
            ))}
          </Tags>
        </>
      )}
    </Composition>
  );
}
