import React from "react";
import {
  Button,
  Popover,
  Position,
  Menu,
  MenuItem,
  MenuDivider,
  Tooltip,
} from "@blueprintjs/core";
import { useAuth0 } from "@auth0/auth0-react";

export function UserMenu() {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Popover
        content={
          <Menu>
            <MenuItem icon="person" text="Profile" />
            <MenuItem icon="settings" text="Settings" />
            <MenuDivider />
            {!isAuthenticated && (
              <MenuItem
                icon="log-in"
                text="Sign In"
                onClick={() => loginWithRedirect()}
              />
            )}
            {isAuthenticated && (
              <MenuItem
                icon="log-out"
                text="Sign Out"
                onClick={() => logout({ returnTo: window.location.origin })}
              />
            )}
          </Menu>
        }
        position={Position.BOTTOM}
      >
        <Tooltip content="User Options">
          <Button icon="user" minimal={true} />
        </Tooltip>
      </Popover>
    </>
  );
}

export default UserMenu;
