import {
  Button,
  Classes,
  Dialog,
  HTMLTable,
  Intent,
  Radio,
  RadioGroup,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getTicketmasterZoneSections } from "../../directorApi";

export interface DeliveryMethodOverridesDialogProps {
  vendor: string;
  vendorEventId: string;
  overrides: { [section: string]: string };
  isOpen: boolean;
  onSave: (newOverrides: { [section: string]: string }) => void;
  onClose: () => void;
}

export function DeliveryMethodOverridesDialog(
  props: DeliveryMethodOverridesDialogProps
) {
  const { isOpen, onSave, onClose, vendor, vendorEventId, overrides } = props;
  const [localOverrides, setLocalOverrides] = useState<{
    [zone: string]: string;
  }>({});

  const { data: zonesSections } = useQuery<{ [zone: string]: string[] }>(
    ["zonesSections", vendor, vendorEventId],
    () => {
      if (vendor === "Ticketmaster" && vendorEventId) {
        return getTicketmasterZoneSections(vendorEventId).then(
          (res) => res.data
        );
      } else {
        return Promise.resolve({});
      }
    }
  );

  useEffect(() => {
    if (zonesSections === undefined) {
      return;
    }

    const zones: { [zone: string]: string } = {};

    if (Object.keys(overrides).length > 0) {
      Object.keys(overrides).forEach((section) => {
        Object.keys(zonesSections).forEach((zone) => {
          if (zonesSections[zone].includes(section)) {
            zones[zone] = overrides[section];
          }
        });
      });
    }

    setLocalOverrides(zones);
  }, [overrides, zonesSections]);

  function handleZoneChange(zone: string, value: string) {
    let newZoneOverrides = { ...localOverrides };
    newZoneOverrides[zone] = value;

    setLocalOverrides(newZoneOverrides);
  }

  function handleSave() {
    if (zonesSections === undefined) return;

    const newOverrides: { [section: string]: string } = {};

    Object.keys(localOverrides).forEach((zone) => {
      const sections = zonesSections[zone];

      sections.forEach((section) => {
        newOverrides[section] = localOverrides[zone];
      });
    });

    onSave(newOverrides);
  }

  function handleClear() {
    onSave({});
  }

  return (
    <Dialog
      icon="wrench"
      title="Delivery Method Overrides"
      isOpen={isOpen}
      style={{ width: "auto", maxHeight: "600px" }}
      onClose={onClose}
    >
      <div
        className={Classes.DIALOG_BODY}
        style={{ overflow: "auto", maxHeight: "500px" }}
      >
        <HTMLTable condensed={true}>
          <thead>
            <tr>
              <th>Zone</th>
              <th>Delivery Method</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(zonesSections ?? {}).map((zone) => (
              <tr key={zone}>
                <td>{zone}</td>
                <td>
                  <RadioGroup
                    inline={true}
                    selectedValue={localOverrides[zone] || ""}
                    onChange={(e) =>
                      handleZoneChange(zone, e.currentTarget.value)
                    }
                  >
                    <Radio value="" label="Default" />
                    <Radio value="WillCall" label="Will Call" />
                    <Radio value="Shipping" label="Shipping" />
                    <Radio value="Upload" label="Upload" />
                    <Radio value="Transfer" label="Transfer" />
                  </RadioGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Cancel</Button>
          <Button intent={Intent.DANGER} onClick={handleClear}>
            Clear
          </Button>
          <Button intent={Intent.PRIMARY} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
