import {
  Navbar,
  Alignment,
  Button,
  Tooltip,
  Colors,
  Tag,
} from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenu from "./UserMenu";
import { PageTitle } from "./PageTitle";

export function TopBar() {
  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Button minimal={true} style={{ marginRight: "1rem" }}>
          Director <FontAwesomeIcon icon="bullhorn" color={Colors.GRAY1} />
        </Button>
        <Tag minimal={true}>v{process.env.REACT_APP_DIRECTOR_VERSION}</Tag>
        <PageTitle />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Navbar.Divider />
        <Tooltip content="Status (Coming soon)">
          <Button icon="pulse" minimal={true} />
        </Tooltip>
        <Tooltip content="Notifications (Coming soon)">
          <Button icon="notifications" minimal={true} />
        </Tooltip>
        <UserMenu />
      </Navbar.Group>
    </Navbar>
  );
}
