import axios from "axios";
import { IIdName } from "./models/common/IIdName";
import { ISearchEventsRequest } from "./models/ISearchEventsRequest";
import { IEventSummary } from "./models/IEventSummary";
import { IListing } from "./models/IListing";
import { ICrawlerProperties } from "./models/ICrawlerProperties";
import { IUpdateCrawlerRequest } from "./models/IUpdateCrawlerRequest";
import qs from "qs";
import { IAddTagsRequest } from "./models/IAddTagsRequest";
import { IBulkEventRequest } from "./models/IBulkEventRequest";

axios.defaults.baseURL = process.env.REACT_APP_DIRECTOR_API_URL;
axios.defaults.validateStatus = (status: number) => status < 400; // 304 returns error (dumb)
axios.defaults.paramsSerializer = (params: any) =>
  qs.stringify(params, { arrayFormat: "repeat" });

export function setAxiosTokenInterceptor(accessToken: string) {
  axios.interceptors.request.use((req) => {
    if (accessToken) {
      req.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return req;
  });
}

export async function listPerformers() {
  return await axios.get<IIdName[]>("/inventory/performers/list");
}

export async function listVenues() {
  return await axios.get<IIdName[]>("/inventory/venues/list");
}

export async function searchEvents(request: ISearchEventsRequest) {
  return await axios.get<IEventSummary[]>("inventory/events", {
    params: request,
  });
}

export async function getListings(eventId: number) {
  return await axios.get<IListing[]>(`inventory/events/${eventId}/listings`);
}

export async function getCrawlerProperties(
  vendor: string,
  vendorEventId: string
) {
  let response = await axios.get<ICrawlerProperties>(
    `crawlers/${vendor}/${vendorEventId}`
  );

  if (response.data.lastCrawl) {
    response.data.lastCrawl = new Date(response.data.lastCrawl);
  }

  return response;
}

export async function addOfferCode(
  vendor: string,
  vendorEventId: string,
  offerCode: string
) {
  return await axios.post(`crawlers/${vendor}/${vendorEventId}/offerCodes`, {
    offerCode,
  });
}

export async function removeOfferCode(
  vendor: string,
  vendorEventId: string,
  offerCode: string
) {
  return await axios.delete(
    `crawlers/${vendor}/${vendorEventId}/offerCodes/${offerCode}`
  );
}

export async function updateCrawler(
  vendor: string,
  vendorEventId: string,
  request: IUpdateCrawlerRequest
) {
  await axios.put(`crawlers/${vendor}/${vendorEventId}`, request);
}

export async function updateEventTags(eventId: number, tags: string[]) {
  return await axios.put<IEventSummary>(`inventory/events/${eventId}/tags`, {
    tags,
  });
}

export async function addEventsTags(request: IAddTagsRequest) {
  return await axios.post<IEventSummary[]>(
    "inventory/events/bulk/tags",
    request
  );
}

export async function removeEventsTags(request: IBulkEventRequest) {
  return await axios.put<IEventSummary[]>(
    `inventory/events/bulk/tags`,
    request
  );
}

export async function getTicketmasterZoneSections(eventId: string) {
  return await axios.get<{ [zone: string]: string[] }>(
    `ticketmaster/${eventId}/sections`
  );
}
