import {
  Popover,
  Classes,
  Button,
  H5,
  Intent,
  IPopoverSharedProps,
} from "@blueprintjs/core";
import React from "react";

export interface IPopoverConfirmDangerProps extends IPopoverSharedProps {
  titleText: string;
  messageText: string;
  confirmButtonLabel: string;
  children: JSX.Element;
  onConfirmClick(): void;
}

export function PopoverConfirmDanger({
  titleText,
  messageText,
  confirmButtonLabel,
  onConfirmClick,
  children,
  ...props
}: IPopoverConfirmDangerProps) {
  const content = (
    <div>
      <H5>{titleText}</H5>
      <p>{messageText}</p>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}
      >
        <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button
          intent={Intent.DANGER}
          className={Classes.POPOVER_DISMISS}
          onClick={onConfirmClick}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      content={content}
      {...props}
    >
      {children}
    </Popover>
  );
}
