import { Composition } from "atomic-layout";
import React from "react";
import { useMutation, useQuery } from "react-query";
import {
  getListings,
  listPerformers,
  listVenues,
  searchEvents,
} from "../../directorApi";
import { IEventSummary } from "../../models/IEventSummary";
import { FilterPanel } from "./FilterPanel";
import { EventsPanel } from "./EventsPanel";
import { SelectedEventPanel } from "./SelectedEventPanel";
import { ISearchEventsRequest } from "../../models/ISearchEventsRequest";

const areas = `
    filters events
    filters selectedEvent
`;

export function Main() {
  const [events, setEvents] = React.useState<IEventSummary[] | undefined>([]);

  const { data: performers } = useQuery(
    "performers",
    () =>
      listPerformers().then((res) =>
        res.data.sort((a, b) => a.name.localeCompare(b.name))
      ),
    { refetchOnWindowFocus: false, cacheTime: 0 }
  );

  const { data: venues } = useQuery(
    "venues",
    () =>
      listVenues().then((res) =>
        res.data.sort((a, b) => a.name.localeCompare(b.name))
      ),
    { refetchOnWindowFocus: false, cacheTime: 0 }
  );
  const searchMutation = useMutation((filters: ISearchEventsRequest) =>
    searchEvents(filters).then((res) => res.data)
  );

  function handleSearchClick(searchFilters: ISearchEventsRequest) {
    if (searchFilters.query === "") {
      searchFilters.query = undefined;
    }
    setEvents(undefined);
    searchMutation.mutate(searchFilters, {
      onSuccess: (res) => setEvents(res),
    });
  }

  const [selectedEvent, setSelectedEvent] = React.useState<IEventSummary>();

  function handleEventsChanged(changedEvents: IEventSummary[]) {
    if (changedEvents.length === 0 || !events) {
      return;
    }

    setEvents(
      events.map((e) => changedEvents.find((c) => c.eventId === e.eventId) ?? e)
    );
  }

  const { data: listings } = useQuery(
    ["listings", selectedEvent?.eventId],
    () => {
      if (selectedEvent) {
        return getListings(selectedEvent.eventId).then((res) => res.data);
      }
      return [];
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <Composition
        style={{ height: "calc(100vh - 50px)" }}
        areas={areas}
        templateCols={"auto 1fr"}
      >
        {({ Events, Filters, SelectedEvent }) => (
          <>
            <Filters>
              <FilterPanel
                onFilter={handleSearchClick}
                performers={performers}
                venues={venues}
              />
            </Filters>
            <Events>
              <EventsPanel
                events={events}
                onEventSelected={(event) => setSelectedEvent(event)}
                onEventsChanged={handleEventsChanged}
              />
            </Events>
            <SelectedEvent>
              <SelectedEventPanel listings={listings} event={selectedEvent} />
            </SelectedEvent>
          </>
        )}
      </Composition>
    </>
  );
}
