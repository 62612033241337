import { ValueFormatterParams } from "ag-grid-community";
import accounting from "accounting";
import { format, parse } from "date-fns";

export function formatAddress(
  streetAddress1: string,
  streetAddress2: string | undefined,
  city: string,
  region: string,
  country: string,
  postalCode: string
) {
  var address = streetAddress1;

  if (streetAddress2) {
    address = address + ", " + streetAddress2;
  }

  address = address + ", " + city + ", " + region + " " + postalCode;

  return address;
}

export function formatSeats(
  seatFrom: number | string,
  seatThru: number | string
) {
  let seatNumberFrom: number;
  let seatNumberThru: number;

  if (typeof seatFrom === "string") {
    seatNumberFrom = parseInt(seatFrom);
  } else {
    seatNumberFrom = seatFrom;
  }

  if (typeof seatThru === "string") {
    seatNumberThru = parseInt(seatThru);
  } else {
    seatNumberThru = seatThru;
  }

  if (isNaN(seatNumberFrom) || isNaN(seatNumberThru)) {
    return `${seatFrom} - ${seatThru}`; // Don't know what to do if they're not proper numbers, just return "this - that"
  }

  if (seatNumberFrom === seatNumberThru) {
    return seatFrom.toString();
  }

  return `${seatNumberFrom} - ${seatNumberThru}`;
}

export function currencyFormatter(
  params: ValueFormatterParams | number | undefined
) {
  if (params === undefined) {
    return "";
  }

  if (typeof params === "number") {
    return accounting.formatMoney(params);
  }

  return accounting.formatMoney(params.value);
}

export function shortDateFormatter(
  params: ValueFormatterParams | Date | undefined
) {
  if (params === undefined) {
    return "";
  }

  if (params instanceof Date) {
    return format(params, "M/d/yyyy");
  }

  return format(parse(params.value, "yyyy-MM-dd", new Date()), "M/d/yyyy");
}

export function shortEventTimeFormatter(
  params: ValueFormatterParams | string | Date | undefined
): string {
  if (params === undefined) {
    return "";
  }

  if (typeof params === "string") {
    return format(parse(params, "hh:mm:ss", new Date()), "h:mm a");
  }

  if (params instanceof Date) {
    if (params.getHours() === 23 && params.getMinutes() === 59) {
      return "TBA";
    }

    return format(params, "h:mm a");
  }

  if (params.value instanceof Date) {
    if (params.value.getHours() === 23 && params.value.getMinutes() === 59) {
      return "TBA";
    }

    return format(params.value, "h:mm a");
  }

  if (typeof params.value === "string") {
    return format(parse(params.value, "HH:mm:ss", new Date()), "h:mm a");
  }

  return "";
}

export function shortDayFormatter(date: Date) {
  return format(date, "E");
}

export function shortEventDateTimeFormatter(
  params: ValueFormatterParams | Date | undefined
) {
  if (params === undefined) {
    return "";
  }

  if (params instanceof Date) {
    if (
      (params.getHours() === 23 && params.getMinutes() === 59) ||
      (params.getHours() === 0 && params.getMinutes() === 0)
    ) {
      return format(params, "M/d/yyyy");
    }

    return format(params, "M/d/yyyy h:mm a");
  }

  if (params.value instanceof Date) {
    if (
      (params.value.getHours() === 23 && params.value.getMinutes() === 59) ||
      (params.value.getHours() === 0 && params.value.getMinutes() === 0)
    ) {
      return format(params.value, "M/d/yyyy");
    }

    return format(params.value, "M/d/yyyy h:mm a");
  }

  if (params.value === "") return "";

  const date = new Date(params.value);

  if (date.getFullYear() < 1900) {
    return "";
  }

  return format(date, "M/d/yyyy h:mm a");
}

export function longEventDateTimeFormatter(date: Date) {
  if (date.getHours() === 23 && date.getMinutes() === 59) {
    return format(date, "E MMM d, yyyy");
  }

  return `${format(date, "E MMM d, yyyy")} at ${format(date, "h:mm a")}`;
}

export function shortGuidFormatter(
  params: ValueFormatterParams | string | undefined
): string {
  if (params === undefined) {
    return "";
  }

  if (typeof params === "string") {
    // Not a guid, don't make any assumptions, just output original stringify
    if (params.length !== 36) {
      return params;
    }

    return params.substring(0, 8);
  }

  return shortGuidFormatter(params.value);
}
