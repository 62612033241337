import React, { useEffect, useState } from "react";
import {
  IconName,
  Popover,
  Button,
  Menu,
  ButtonGroup,
} from "@blueprintjs/core";
import { MenuItem } from "@blueprintjs/core";
import { Position } from "@blueprintjs/core";

export interface IMenuPickerItem {
  id: number;
  text: string;
  icon: IconName | undefined;
}

export interface IMenuPickerProps {
  defaultText?: string;
  defaultItemId?: number;
  items: IMenuPickerItem[];
  loading?: boolean;
  selectedItem?: IMenuPickerItem | undefined; // For controlled mode
  onSelectedItemChange?: IMenuPickerItemChangeCallback; // For controlled mode
  disabled?: boolean;
}

export interface IMenuPickerItemChangeCallback {
  (item: IMenuPickerItem | undefined): void;
}

export function MenuPickerButton({
  defaultText,
  defaultItemId,
  items,
  loading,
  selectedItem,
  onSelectedItemChange,
  ...props
}: IMenuPickerProps) {
  if (
    (!defaultText && defaultItemId === undefined) ||
    (defaultText && defaultItemId)
  ) {
    throw new Error("Must provide either defaultText or defaultItemId");
  }

  const [internalSelectedItem, setInternalSelectedItem] = useState<
    IMenuPickerItem | undefined
  >();
  const [isOpen, setIsOpen] = useState(false);

  const defaultItem = items.find((i) => i.id === defaultItemId);

  const [currentItem, setCurrentItem] = useState(
    selectedItem ?? internalSelectedItem
  );

  function setSelectedItem(item: IMenuPickerItem | undefined) {
    setCurrentItem(item);

    if (onSelectedItemChange) {
      onSelectedItemChange(item);
    } else {
      setInternalSelectedItem(item);
    }
  }

  useEffect(() => {
    if (
      defaultItem !== undefined &&
      (currentItem === undefined || !items.some((i) => i.id === currentItem.id))
    ) {
      setSelectedItem(defaultItem);
    } // eslint-disable-next-line
  }, [defaultItem, currentItem, items]);

  if (items.length === 0) {
    if (!defaultText) {
      return null;
    }

    return (
      <Button
        icon="warning-sign"
        text={defaultText}
        rightIcon="caret-down"
        disabled={true}
        loading={loading}
      />
    );
  }

  function handleSelect(item: IMenuPickerItem | undefined) {
    setSelectedItem(item);
  }

  if (!defaultItem && !defaultText) {
    throw new Error("defaultItemId not found in items");
  }

  return (
    <Popover
      position={Position.BOTTOM}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      captureDismiss={true}
    >
      <ButtonGroup>
        {currentItem ? (
          <Button
            icon={currentItem.icon}
            text={currentItem.text}
            rightIcon="caret-down"
            loading={loading}
            onClick={() => setIsOpen(!isOpen)}
            {...props}
          />
        ) : (
          <Button
            text={defaultText}
            rightIcon="caret-down"
            loading={loading}
            onClick={() => setIsOpen(!isOpen)}
            {...props}
          />
        )}
        {defaultText && currentItem ? (
          <Button
            icon="cross"
            onClick={() => handleSelect(undefined)}
            {...props}
          />
        ) : undefined}
      </ButtonGroup>
      <Menu>
        {items.map((item) => {
          return (
            <MenuItem
              key={item.id}
              icon={item.icon}
              text={item.text}
              onClick={() => handleSelect(item)}
              active={currentItem?.id === item.id}
            />
          );
        })}
      </Menu>
    </Popover>
  );
}
