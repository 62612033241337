import {
  Button,
  Classes,
  ControlGroup,
  Dialog,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  TagInput,
} from "@blueprintjs/core";
import { SelectionChangedEvent } from "ag-grid-community";
import { Composition } from "atomic-layout";
import { useState } from "react";
import { useMutation } from "react-query";
import {
  addEventsTags,
  addOfferCode,
  removeEventsTags,
  updateEventTags,
} from "../../directorApi";
import { IAddTagsRequest } from "../../models/IAddTagsRequest";
import { IBulkEventRequest } from "../../models/IBulkEventRequest";
import { IEventSummary } from "../../models/IEventSummary";
import { EventsGrid } from "./EventsGrid";
import { IconNames } from "@blueprintjs/icons";
import {AppToaster} from "../AppToaster";
import {shortDateFormatter} from "../../utilities/Formatters";

export interface EventsPanelProps {
  events?: IEventSummary[];

  onEventSelected(event?: IEventSummary): void;

  onEventsChanged(changedEvents: IEventSummary[]): void;
}

interface EventTags {
  eventId: number;
  tags: string[];
}

export function EventsPanel({
  events,
  onEventSelected,
  onEventsChanged,
}: EventsPanelProps) {
  const [filterText, setFilterText] = useState<string>();
  const [tags, setTags] = useState<string[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<IEventSummary[]>();
  const [isEditTagsDialogOpen, setIsEditTagsDialogOpen] = useState(false);
  const [isAddTagsDialogOpen, setIsAddTagsDialogOpen] = useState(false);
  const [isRemoveTagsDialogOpen, setIsRemoveTagsDialogOpen] = useState(false);
  const [isAddCodesDialogOpen, setIsAddCodesDialogOpen] = useState(false);
  const [codeInput, setCodeInput] = useState<string>();

  function handleEventsChanged(changedEvents: IEventSummary[]) {
    if (selectedEvents) {
      setSelectedEvents(
        selectedEvents.map(
          (e) => changedEvents.find((c) => c.eventId === e.eventId) ?? e
        )
      );
    }

    onEventsChanged(changedEvents);
  }

  function handleEventSelected(event: SelectionChangedEvent) {
    const selected = event.api.getSelectedRows();

    setSelectedEvents(selected);

    if (selected.length !== 1) {
      onEventSelected();
      return;
    }

    onEventSelected(undefined); // To force the listings grid to requery if the user clicks an event that was already selected
    onEventSelected(selected[0]);
  }

  const addSingleOfferCode = useMutation(
    (request: { event: IEventSummary; code: string }) =>
      addOfferCode(
        request.event.vendor,
        request.event.vendorEventId,
        request.code
      )
  );

  const addOfferCodes = async (events: IEventSummary[], code: string) => {
    if (code === "") return;
    
    for (const event of events) {
      try {
        await addSingleOfferCode.mutateAsync({ event, code });
        AppToaster.show({
          message: `Added offer code ${code} to ${event.name} on ${shortDateFormatter(new Date(event.date))}`,
          intent: Intent.SUCCESS,
        });
      }
      catch {
        AppToaster.show({
          message: `Error adding code: ${code} for event ${event.name} on ${shortDateFormatter(new Date(event.date))}`,
          intent: Intent.DANGER,
        });
      }
    }
    
    setIsAddCodesDialogOpen(false);
  };

  const addTags = useMutation(
    (request: IAddTagsRequest) => addEventsTags(request),
    { onSuccess: (res) => handleEventsChanged(res.data) }
  );

  const editTags = useMutation(
    ({ eventId, tags }: EventTags) => updateEventTags(eventId, tags),
    { onSuccess: (res) => handleEventsChanged([res.data]) }
  );

  const removeTags = useMutation(
    (request: IBulkEventRequest) => removeEventsTags(request),
    {
      onSuccess: (res) => handleEventsChanged(res.data),
    }
  );

  async function handleTagsAdd(eventIds: number[], tags: string[]) {
    await addTags
      .mutateAsync({
        eventIds,
        tags,
      })
      .then(() => setIsAddTagsDialogOpen(false));
  }

  async function handleTagsEdit(eventId: number, tags: string[]) {
    await editTags
      .mutateAsync({
        eventId,
        tags,
      })
      .then(() => setIsEditTagsDialogOpen(false));
  }

  async function handleTagsRemove(eventIds: number[]) {
    await removeTags
      .mutateAsync({ eventIds })
      .then(() => setIsRemoveTagsDialogOpen(false));
  }

  const areas = `
    toolbar
    grid
    `;

  const toolbarAreas = `filter space actions`;

  return (
    <>
      <Composition
        areas={areas}
        marginTop="8px"
        gap={"8px"}
        style={{ height: "calc(100% - 8px)" }}
        templateRows="auto 1fr"
      >
        {({ Toolbar, Grid }) => (
          <>
            <Toolbar>
              <Composition
                areas={toolbarAreas}
                gap={"1rem"}
                templateCols="auto 1fr auto"
                marginRight="1rem"
                alignItems="center"
              >
                {({ Filter, Space, Actions }) => (
                  <>
                    <Filter>
                      <InputGroup
                        type="search"
                        leftIcon="search"
                        placeholder="Filter"
                        fill={false}
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                    </Filter>
                    <Actions gap="1rem" flex={true} alignItems="center">
                      {selectedEvents && selectedEvents.length > 0 && (
                        <>
                          {selectedEvents && selectedEvents.length > 1 && (
                            <Button
                              icon="lock"
                              onClick={() => setIsAddCodesDialogOpen(true)}
                            >
                              Add Codes
                            </Button>
                          )}

                          <Popover
                            content={
                              <Menu>
                                <MenuItem
                                  text="Add"
                                  icon="add"
                                  onClick={() => {
                                    setTags([]);
                                    setIsAddTagsDialogOpen(
                                      !isAddTagsDialogOpen
                                    );
                                  }}
                                />

                                {selectedEvents.length === 1 &&
                                  selectedEvents[0].tags?.length > 0 && (
                                    <MenuItem
                                      text="Edit"
                                      icon="edit"
                                      onClick={() => {
                                        setTags(selectedEvents[0].tags);
                                        setIsEditTagsDialogOpen(
                                          !isEditTagsDialogOpen
                                        );
                                      }}
                                    />
                                  )}
                                <MenuItem
                                  text="Remove"
                                  icon="remove"
                                  onClick={() =>
                                    setIsRemoveTagsDialogOpen(true)
                                  }
                                />
                              </Menu>
                            }
                          >
                            <Button text="Tags" icon="tag" />
                          </Popover>
                          <Dialog
                            icon="add"
                            title="Add Codes"
                            isOpen={isAddCodesDialogOpen}
                            onOpening={() => setCodeInput("")}
                            onClose={() => setIsAddCodesDialogOpen(false)}
                          >
                            <div className={Classes.DIALOG_BODY}>
                              <ControlGroup>
                                <InputGroup
                                  leftIcon={IconNames.LOCK}
                                  value={codeInput}
                                  disabled={addSingleOfferCode.isLoading}
                                  autoFocus={true}
                                  fill={true}
                                  placeholder={"Enter offer code"}
                                  onChange={(e) => setCodeInput(e.target.value)}
                                  onKeyPress={async (e) => {
                                    if (e.key === "Enter") {
                                      await addOfferCodes(
                                        selectedEvents,
                                        codeInput ?? ""
                                      );
                                    }
                                  }}
                                />
                                <Button
                                  icon={IconNames.ADD}
                                  text="Add"
                                  intent={Intent.PRIMARY}
                                  onClick={async () =>
                                    await addOfferCodes(
                                      selectedEvents,
                                      codeInput ?? ""
                                    )
                                  }
                                  loading={addSingleOfferCode.isLoading}
                                />
                              </ControlGroup>
                            </div>
                          </Dialog>
                          <Dialog
                            icon="add"
                            title="Add Tags"
                            isOpen={isAddTagsDialogOpen}
                            onClose={() => setIsAddTagsDialogOpen(false)}
                          >
                            <div className={Classes.DIALOG_BODY}>
                              <TagInput
                                leftIcon="tag"
                                tagProps={{ minimal: true }}
                                placeholder="Separate values with commas..."
                                onChange={(values) =>
                                  setTags(
                                    values.map((value: React.ReactNode) =>
                                      value!.toString()
                                    )
                                  )
                                }
                                rightElement={
                                  <Button
                                    icon="cross"
                                    minimal={true}
                                    onClick={() => setTags([])}
                                  />
                                }
                                values={tags}
                              />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button
                                  onClick={() => setIsAddTagsDialogOpen(false)}
                                  disabled={addTags.isLoading}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  intent={Intent.PRIMARY}
                                  loading={addTags.isLoading}
                                  onClick={() =>
                                    handleTagsAdd(
                                      selectedEvents.map((e) => e.eventId),
                                      tags
                                    )
                                  }
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </Dialog>
                          <Dialog
                            icon="edit"
                            title="Edit Tags"
                            isOpen={isEditTagsDialogOpen}
                            autoFocus={true}
                            onClose={() => setIsEditTagsDialogOpen(false)}
                          >
                            <div className={Classes.DIALOG_BODY}>
                              <TagInput
                                leftIcon="tag"
                                tagProps={{ minimal: true }}
                                placeholder="Separate values with commas..."
                                onChange={(values) =>
                                  setTags(
                                    values.map((value: React.ReactNode) =>
                                      value!.toString()
                                    )
                                  )
                                }
                                rightElement={
                                  <Button
                                    icon="cross"
                                    minimal={true}
                                    onClick={() => setTags([])}
                                  />
                                }
                                values={tags}
                              />
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button
                                  onClick={() => setIsEditTagsDialogOpen(false)}
                                  disabled={editTags.isLoading}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  intent={Intent.PRIMARY}
                                  loading={editTags.isLoading}
                                  onClick={() =>
                                    handleTagsEdit(
                                      selectedEvents[0].eventId,
                                      tags
                                    )
                                  }
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          </Dialog>
                          <Dialog
                            icon="remove"
                            title="Remove Tags"
                            isOpen={isRemoveTagsDialogOpen}
                            onClose={() => setIsRemoveTagsDialogOpen(false)}
                          >
                            <div className={Classes.DIALOG_BODY}>
                              <p>
                                Are you sure you want to remove all of the tags
                                from the selected events?
                              </p>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button
                                  onClick={() =>
                                    setIsRemoveTagsDialogOpen(false)
                                  }
                                  disabled={removeTags.isLoading}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  intent={Intent.DANGER}
                                  loading={removeTags.isLoading}
                                  onClick={() =>
                                    handleTagsRemove(
                                      selectedEvents.map((e) => e.eventId)
                                    )
                                  }
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          </Dialog>
                        </>
                      )}
                    </Actions>
                  </>
                )}
              </Composition>
            </Toolbar>
            <Grid>
              <EventsGrid
                events={events}
                onRowSelected={handleEventSelected}
                quickFilterText={filterText}
              />
            </Grid>
          </>
        )}
      </Composition>
    </>
  );
}
