import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
} from "ag-grid-community";
import { currencyFormatter, formatSeats } from "../../utilities/Formatters";
import { IListing } from "../../models/IListing";
import { CellRendererTick } from "../shared/Grid/CellRendererTick";
import { CellRendererWarning } from "../shared/Grid/CellRendererWarning";
import { CellRendererExpiration } from "../shared/Grid/CellRendererExpiration";
import {NaturalComparator} from "../shared/StringComparators";

const columnDefs: ColDef[] = [
  { headerName: "Id", field: "listingId", hide: true },
  { 
    headerName: "Section", 
    field: "section", 
    flex: 1,
    comparator: NaturalComparator,
  },
  {
    headerName: "Row",
    field: "row",
    width: 100,
    cellStyle: { textAlign: "center" },
    comparator: NaturalComparator
  },
  {
    headerName: "Seats",
    valueGetter: (params) =>
      formatSeats(params.data.seatFrom, params.data.seatThru),
    cellStyle: { textAlign: "center" },
  },
  {
    headerName: "Qty",
    field: "quantity",
    width: 100,
    cellStyle: { textAlign: "center" },
  },
  {
    headerName: "Cost",
    field: "unitCost",
    width: 150,
    cellStyle: { textAlign: "right" },
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Price",
    field: "unitPrice",
    width: 150,
    cellStyle: { textAlign: "right" },
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Broadcast",
    field: "isBroadcasting",
    width: 100,
    cellStyle: { textAlign: "center" },
    cellRenderer: "cellRendererTick",
  },
  { headerName: "Splits", field: "splitType" },
  { headerName: "Delivery", field: "deliveryMethod" },
  { headerName: "Stock", field: "ticketStock" },
  { 
    headerName: "Reserved",
    field: "reservedUntil",
    cellStyle: { textAlign: "center" },
    cellRenderer: "cellRendererExpiration",
  },
  {
    headerName: "Disclosures",
    field: "disclosures",
    cellRenderer: "cellRendererWarning",
  },
  {
    headerName: "Notes",
    field: "externalNotes",
    flex: 2,
    cellStyle: { textAlign: "center" },
  },
];

export interface ListingsGridProps {
  listings?: IListing[];
  quickFilterText?: string;
}

export function ListingsGrid({ listings, quickFilterText }: ListingsGridProps) {
  const [gridApi, setGridApi] = useState<GridApi>();

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    if (!listings) {
      gridApi.showLoadingOverlay();
      return;
    }

    gridApi.setRowData(listings);
  }, [gridApi, listings]);

  function handleGridReady(e: GridReadyEvent) {
    setGridApi(e.api);
    e.api.sizeColumnsToFit();
  }

  function handleRowDataChanged(e: RowDataChangedEvent) {
    e.columnApi.autoSizeAllColumns(false);
  }

  return (
    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={{ sortable: true, resizable: true }}
        suppressCellSelection={true}
        rowSelection="single"
        immutableData={true}
        getRowNodeId={(data) => data.listingId}
        onGridReady={handleGridReady}
        onRowDataChanged={handleRowDataChanged}
        onFirstDataRendered={handleRowDataChanged}
        quickFilterText={quickFilterText}
        suppressAutoSize={false}
        frameworkComponents={{
          cellRendererTick: CellRendererTick,
          cellRendererWarning: CellRendererWarning,
          cellRendererExpiration: CellRendererExpiration,
        }}
      />
    </div>
  );
}
