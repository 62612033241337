import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import {
  currencyFormatter,
  shortDateFormatter,
  shortEventDateTimeFormatter,
  shortEventTimeFormatter,
} from "../../utilities/Formatters";
import { IEventSummary } from "../../models/IEventSummary";
import { CellRendererTags } from "../shared/Grid/CellRendererTags";
import { EventsGridStatusBar } from "./EventsGridStatusBar";
import { IPresale } from "../../models/IPresale";

const columnDefs: ColDef[] = [
  { headerName: "Id", field: "eventId", hide: true },
  { headerName: "Vendor", field: "vendor", hide: true },
  { headerName: "VendorEventId", field: "vendorEventId", hide: true },
  { headerName: "Performer", field: "primaryPerformerName", hide: true },
  { headerName: "Event", field: "name", flex: 1 },
  {
    headerName: "Date",
    field: "date",
    valueFormatter: shortDateFormatter,
    cellStyle: { textAlign: "center" },
  },
  {
    headerName: "Time",
    field: "time",
    valueFormatter: shortEventTimeFormatter,
  },
  { headerName: "Venue", field: "venueName" },
  { headerName: "City", field: "city" },
  { headerName: "State", field: "state" },
  {
    headerName: "Public",
    field: "publicSaleDate",
    valueGetter: (params) => params.data.publicSaleDate === null ? "" : params.data.publicSaleDate,
    valueFormatter: shortEventDateTimeFormatter,
    cellStyle: { textAlign: "center" },
  },
  {
    headerName: "Presale",
    valueGetter: (params) => params.data.presales?.length > 0 ? params.data.presales.reduce((a: IPresale, b: IPresale) => new Date(a.date) < new Date(b.date) ? a : b).date : "",     
    valueFormatter: shortEventDateTimeFormatter,
    cellStyle: { textAlign: "center" },
  },
  {
    headerName: "Listings",
    field: "numListings",
    cellStyle: { textAlign: "right" },
  },
  {
    headerName: "Tickets",
    field: "numTickets",
    cellStyle: { textAlign: "right" },
  },
  {
    headerName: "Cost",
    field: "totalCost",
    cellStyle: { textAlign: "right" },
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Tags",
    field: "tags",
    flex: 2,
    cellRenderer: "cellRendererTags",
  },
];

export interface EventsGridProps {
  events: IEventSummary[] | undefined;
  quickFilterText?: string;
  onRowSelected: (event: SelectionChangedEvent) => void;
}

export function EventsGrid({
  events,
  onRowSelected,
  quickFilterText,
}: EventsGridProps) {
  const [gridApi, setGridApi] = useState<GridApi>();

  function handleGridReady(e: GridReadyEvent) {
    setGridApi(e.api);
    e.api.sizeColumnsToFit();
  }

  useEffect(() => {
    if (!gridApi) {
      return;
    }

    if (!events) {
      gridApi.showLoadingOverlay();
      return;
    }

    gridApi.setRowData(events);
  }, [gridApi, events]);

  function handleRowDataChanged(e: RowDataChangedEvent) {
    e.columnApi.autoSizeAllColumns(false);
  }

  const statusBar = {
    statusPanels: [
      { statusPanel: "agTotalRowCountComponent", align: "left" },
      { statusPanel: "agFilteredRowCountComponent", align: "left" },
      { statusPanel: "agSelectedRowCountComponent", align: "center" },
    ],
  };

  return (
    <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={{ sortable: true, resizable: true }}
        suppressCellSelection={true}
        rowSelection="multiple"
        immutableData={true}
        getRowNodeId={(data) => data.eventId}
        onGridReady={handleGridReady}
        onRowDataChanged={handleRowDataChanged}
        onFirstDataRendered={handleRowDataChanged}
        onRowSelected={onRowSelected}
        onRowClicked={onRowSelected}
        //statusBar={{statusPanels: [{statusPanel: "eventsGridStatusBar"}]}}
        statusBar={statusBar}
        quickFilterText={quickFilterText}
        enableCellTextSelection={true}
        frameworkComponents={{
          cellRendererTags: CellRendererTags,
          eventsGridStatusBar: EventsGridStatusBar,
        }}
      />
    </div>
  );
}
