import React from "react";
import ReactDOM from "react-dom";
import { FocusStyleManager } from "@blueprintjs/core";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./components/App";
import "./css/index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { LicenseManager } from "ag-grid-enterprise";

FocusStyleManager.onlyShowFocusOnTabs();

LicenseManager.setLicenseKey(
  "CompanyName=Thirtytech Inc,LicensedGroup=ThirtyTech,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-016198,ExpiryDate=8_June_2022_[v2]_MTY1NDY0MjgwMDAwMA==1caf30e0e577eab1a4a3d55c533ca691"
);
const queryClient = new QueryClient();

const render = () => {
  ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN ?? ""}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID ?? ""}
      audience={process.env.REACT_APP_AUTH_AUDIENCE ?? ""}
      scope={"manage:arbitrage"}
      redirectUri={window.location.origin}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Auth0Provider>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./components/App", render);
}
