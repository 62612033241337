import React, { useEffect } from "react";
import { TopBar } from "./topbar/TopBar";
import { ErrorBoundary } from "./ErrorBoundary";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@blueprintjs/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { setAxiosTokenInterceptor } from "../directorApi";
import { Main } from "./main/Main";

library.add(faBullhorn);

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: "https://director.brokernerds.com/api",
        scope: "manage:arbitrage",
      }).then(setAxiosTokenInterceptor);
    }
  }, [isAuthenticated, getAccessTokenSilently]);
  if (error) {
    return (
      <div>
        An error occurred with authorization provider... {error.message}
      </div>
    );
  }

  if (isLoading) {
    return <Spinner />; // TODO: Replace with better loading screen(?)
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <div style={{ height: "100vh" }}>
      <ErrorBoundary>
        <TopBar />
        <Main />
      </ErrorBoundary>
    </div>
  );
}

export default App;
