import {
  CrawlerIntervalSelect,
  CrawlerIntervalSelectProps,
} from "./CrawlerIntervalSelect";
import {
  IMenuPickerItem,
  IMenuPickerItemChangeCallback,
  MenuPickerButton,
} from "../shared/MenuPickerButton";
import { Composition } from "atomic-layout";
import { FormGroup, NumericInput } from "@blueprintjs/core";
import { DeliveryMethodOverridesDialog } from "./DeliveryMethodOverridesDialog";
import { useState } from "react";

export interface CrawlerMarginInputProps {
  disabled?: boolean;
  defaultValue?: number;
  onValueChange?: (value: number) => void;
}

export interface CrawlerDeliveryPickerProps {
  disabled?: boolean;
  selectedItem?: IMenuPickerItem;
  onSelectedItemChange?: IMenuPickerItemChangeCallback;
  items: IMenuPickerItem[];
}

export interface CrawlerDeliveryDialogProps {
  vendor: string;
  vendorEventId: string;
  overrides: { [section: string]: string };
  onSave: (newOverrides: { [section: string]: string }) => void;
}

export interface CrawlerSettingsProps {
  intervalProps: CrawlerIntervalSelectProps;
  marginProps: CrawlerMarginInputProps;
  deliveryProps: CrawlerDeliveryPickerProps;
  deliveryDialogProps: CrawlerDeliveryDialogProps;
}

const areas = `
    interval
    margin
    delivery
`;

export function CrawlerSettings(props: CrawlerSettingsProps) {
  const [isDeliveryDialogOpen, setIsDeliveryDialogOpen] = useState(false);
  const deliveryItems = props.deliveryProps.items;

  const customDelivery: IMenuPickerItem = {
    id: -1,
    text: "Custom",
    icon: "locate",
  };

  if (deliveryItems.every((i) => i.id !== -1)) {
    deliveryItems.push(customDelivery);
  }

  function handleDeliveryItemChange(item: IMenuPickerItem | undefined) {
    if (item?.id === -1) {
      setIsDeliveryDialogOpen(true);
    } else {
      props.deliveryProps.onSelectedItemChange?.(item);
    }
  }

  function handleSave(newOverrides: { [section: string]: string }) {
    props.deliveryDialogProps.onSave(newOverrides);
    setIsDeliveryDialogOpen(false);
  }

  return (
    <Composition areas={areas} gap="1em">
      {({ Interval, Margin, Delivery }) => (
        <>
          <Interval>
            <FormGroup
              inline={true}
              label="Interval"
              style={{ marginBottom: "0px" }}
            >
              <CrawlerIntervalSelect
                intervalMinutes={props.intervalProps.intervalMinutes}
                onIntervalChange={props.intervalProps.onIntervalChange}
                disabled={props.intervalProps.disabled}
              />
            </FormGroup>
          </Interval>
          <Margin>
            <FormGroup
              inline={true}
              label="Margin"
              style={{ marginBottom: "0px", width: "165px" }}
            >
              <NumericInput
                placeholder="Margin"
                disabled={props.marginProps.disabled}
                defaultValue={props.marginProps.defaultValue}
                fill={true}
                majorStepSize={1.0}
                minorStepSize={0.01}
                stepSize={0.1}
                min={1.0}
                max={5.0}
                onValueChange={props.marginProps.onValueChange}
              />
            </FormGroup>
          </Margin>
          <Delivery>
            <FormGroup
              inline={true}
              label="Delivery"
              style={{ marginBottom: "0px" }}
            >
              <MenuPickerButton
                items={deliveryItems}
                disabled={props.deliveryProps.disabled}
                defaultItemId={0}
                selectedItem={
                  props.deliveryProps.selectedItem?.id === 0
                    ? Object.keys(props.deliveryDialogProps.overrides).length >
                      0
                      ? customDelivery
                      : undefined
                    : props.deliveryProps.selectedItem
                }
                onSelectedItemChange={handleDeliveryItemChange}
              />
            </FormGroup>
            <DeliveryMethodOverridesDialog
              vendor={props.deliveryDialogProps.vendor}
              vendorEventId={props.deliveryDialogProps.vendorEventId}
              overrides={props.deliveryDialogProps.overrides}
              isOpen={isDeliveryDialogOpen}
              onSave={handleSave}
              onClose={() => setIsDeliveryDialogOpen(false)}
            />
          </Delivery>
        </>
      )}
    </Composition>
  );
}
