import { IStatusPanelParams, RowDataChangedEvent } from "ag-grid-community";
import { Composition } from "atomic-layout";
import { Classes, H6 } from "@blueprintjs/core";
import { useState } from "react";

export function EventsGridStatusBar(props: IStatusPanelParams) {
  const [eventsCount, setEventsCount] = useState(0);

  props.api.addEventListener("rowDataChanged", (e: RowDataChangedEvent) =>
    setEventsCount(e.api.getDisplayedRowCount())
  );

  return (
    <Composition
      areas={`total selected filtered`}
      gap="1rem"
      style={{ minHeight: "35px" }}
      alignContent="center"
    >
      {({ Total, Selected, Filtered }) => (
        <>
          <Total>
            <H6>
              <span className={Classes.TEXT_MUTED}>Total Events: </span>
              {eventsCount}
            </H6>
          </Total>
          <Selected>
            <H6>
              <span className={Classes.TEXT_MUTED}>Selected: </span>
              {props.api.getSelectedRows().length}
            </H6>
          </Selected>
          <Filtered>
            {props.api.getFilterModel()?.items?.length > 0 ? (
              <H6>
                <span className={Classes.TEXT_MUTED}>Filtered: </span>
                {props.api.getFilterModel().items.length}
              </H6>
            ) : null}
          </Filtered>
        </>
      )}
    </Composition>
  );
}
