import { HTMLSelect, OptionProps } from "@blueprintjs/core";

export interface CrawlerIntervalSelectProps {
  intervalMinutes: number;
  disabled: boolean;
  onIntervalChange: (intervalMinutes: number) => void;
}

export function CrawlerIntervalSelect({
  intervalMinutes,
  disabled,
  onIntervalChange,
}: CrawlerIntervalSelectProps) {
  const options: OptionProps[] = [
    { label: "5 minutes", value: 5 },
    { label: "15 minutes", value: 15 },
    { label: "30 minutes", value: 30 },
    { label: "1 hour", value: 60 },
    { label: "2 hours", value: 120 },
    { label: "4 hours", value: 240 },
    { label: "6 hours", value: 360 },
    { label: "12 hours", value: 720 },
    { label: "1 day", value: 1440 },
    { label: "Default", value: -1 },
  ];

  return (
    <HTMLSelect
      options={options}
      value={intervalMinutes}
      disabled={disabled}
      onChange={(event: any) => onIntervalChange(event.target.value)}
    />
  );
}
