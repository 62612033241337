import {
  Button,
  Classes,
  InputGroup,
  Intent,
  Popover,
  PopoverPosition,
  Switch,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Composition } from "atomic-layout";
import { format, formatDistanceToNow, subDays, subMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { QueryClient, useMutation, useQuery } from "react-query";
import { getCrawlerProperties, updateCrawler } from "../../directorApi";
import { ICrawlerProperties } from "../../models/ICrawlerProperties";
import { IEventSummary } from "../../models/IEventSummary";
import { IUpdateCrawlerRequest } from "../../models/IUpdateCrawlerRequest";
import { IMenuPickerItem } from "../shared/MenuPickerButton";
import { ListingsGrid, ListingsGridProps } from "./ListingsGrid";
import { SelectedEventTitle } from "./SelectedEventTitle";
import { PopoverConfirmDanger } from "../shared/PopoverConfirmDanger";
import { CrawlerSettings } from "./CrawlerSettings";
import { CrawlerOfferCodes } from "./CrawlerOfferCodes";

export interface SelectedEventProps extends ListingsGridProps {
  event?: IEventSummary;
  crawlerProperties?: ICrawlerProperties;
}

export function SelectedEventPanel({ event, listings }: SelectedEventProps) {
  const [filterText, setFilterText] = useState<string>();
  const [isDisablePopoverOpen, setIsDisablePopoverOpen] =
    useState<boolean>(false);

  const queryClient = new QueryClient();

  const { data: crawlerProperties, refetch: refetchProperties } = useQuery(
    ["crawlerProperties", event?.eventId],
    () => {
      if (event) {
        return getCrawlerProperties(event.vendor, event.vendorEventId).then(
          (res) => res.data
        );
      }
    }
  );

  const mutation = useMutation((request: IUpdateCrawlerRequest) => {
    if (event) {
      updateCrawler(event.vendor, event.vendorEventId, request)
        .then((r) =>
          queryClient.invalidateQueries(["crawlerProperties", event?.eventId])
        )
        .then((r) => refetchProperties());
    }

    return Promise.resolve();
  });

  const deliveryMethods = (): IMenuPickerItem[] => {
    return [
      { id: 0, text: "Default", icon: undefined },
      { id: 1, text: "Shipping", icon: IconNames.ENVELOPE },
      { id: 2, text: "Upload", icon: IconNames.CLOUD_UPLOAD },
      { id: 3, text: "Transfer", icon: IconNames.MOBILE_PHONE },
      { id: 4, text: "WillCall", icon: IconNames.SHOP },
    ];
  };

  const [selectedDelivery, setSelectedDelivery] = useState<IMenuPickerItem>(
    deliveryMethods()[0]
  );

  useEffect(() => {
    if (crawlerProperties) {
      setSelectedDelivery(
        deliveryMethods().find(
          (d) => d.text === crawlerProperties.deliveryMethodOverride
        ) || deliveryMethods()[0]
      );
    }
  }, [crawlerProperties]);

  const areas = `
      toolbar
      grid
      `;

  const toolbarAreas = `filter event actions`;

  return (
    <Composition
      areas={areas}
      marginTop="8px"
      gap={"8px"}
      style={{ height: "calc(100% - 8px)" }}
      templateRows="auto 1fr"
    >
      {({ Toolbar, Grid }) => (
        <>
          <Toolbar>
            <Composition
              areas={toolbarAreas}
              gap={"1rem"}
              templateCols="auto 1fr auto"
              marginRight="1rem"
              alignItems="center"
            >
              {({ Filter, Event, Actions }) => (
                <>
                  <Filter>
                    <InputGroup
                      type="search"
                      leftIcon="search"
                      placeholder="Filter"
                      fill={false}
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                      style={{ maxWidth: "250px" }}
                    />
                  </Filter>
                  <Event>
                    <SelectedEventTitle event={event} />
                  </Event>
                  <Actions gap="1rem" flex={true} alignItems="center">
                    {event && crawlerProperties && (
                      <>
                        <Tooltip
                          content={
                            !crawlerProperties.lastCrawl ||
                            crawlerProperties.lastCrawl <
                              subDays(new Date(), 365)
                              ? "Never crawled"
                              : `Last crawled ${format(
                                  crawlerProperties.lastCrawl,
                                  "MM/dd/yyyy h:mm a"
                                )}`
                          }
                        >
                          <Tag
                            minimal={true}
                            large={true}
                            icon="automatic-updates"
                            intent={
                              !crawlerProperties.lastCrawl ||
                              crawlerProperties.lastCrawl <
                                subDays(new Date(), 365)
                                ? Intent.DANGER
                                : crawlerProperties.lastCrawl <
                                  subMinutes(
                                    new Date(),
                                    crawlerProperties.crawlIntervalMinutes
                                  )
                                ? Intent.WARNING
                                : Intent.SUCCESS
                            }
                          >
                            {!crawlerProperties.lastCrawl ||
                            crawlerProperties.lastCrawl <
                              subDays(new Date(), 365)
                              ? "Never"
                              : formatDistanceToNow(
                                  crawlerProperties.lastCrawl
                                ) + " ago"}
                          </Tag>
                        </Tooltip>
                        {event && event.vendor === "Ticketmaster" &&
                            <Popover
                                content={
                                  <CrawlerOfferCodes
                                      offerCodes={crawlerProperties.offerCodes}
                                      onChange={() => refetchProperties()}
                                      eventId={event.vendorEventId}
                                      vendor={event.vendor}
                                  />
                                }
                                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                            >
                              <Button icon={IconNames.LOCK}>
                                {crawlerProperties?.offerCodes?.length === 0
                                    ? "No Codes"
                                    : crawlerProperties?.offerCodes?.length === 1
                                        ? crawlerProperties.offerCodes[0]
                                        : `${crawlerProperties?.offerCodes?.length} Codes`}
                              </Button>
                            </Popover>
                        }
                        <Popover
                          position={PopoverPosition.BOTTOM}
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                          captureDismiss={true}
                          content={
                            <CrawlerSettings
                              intervalProps={{
                                intervalMinutes:
                                  crawlerProperties.crawlIntervalMinutes,
                                onIntervalChange: (newInterval) => {
                                  if (!event) {
                                    return;
                                  }

                                  mutation.mutate({
                                    intervalMinutes:
                                      newInterval === -1 ? null : newInterval,
                                  });
                                },
                                disabled:
                                  !crawlerProperties.isEnabled ||
                                  mutation.isLoading,
                              }}
                              marginProps={{
                                disabled:
                                  !crawlerProperties.isEnabled ||
                                  mutation.isLoading,
                                defaultValue: crawlerProperties.margin,
                                onValueChange: (newValue) => {
                                  if (isNaN(newValue)) {
                                    return;
                                  }

                                  mutation.mutate({
                                    margin: newValue,
                                  });
                                },
                              }}
                              deliveryProps={{
                                disabled:
                                  !crawlerProperties.isEnabled ||
                                  mutation.isLoading,
                                items: deliveryMethods(),
                                selectedItem: selectedDelivery,
                                onSelectedItemChange: (newItem) => {
                                  if (!event) {
                                    return;
                                  }

                                  mutation.mutate({
                                    deliveryMethod:
                                      newItem?.text === "Default"
                                        ? ""
                                        : newItem?.text,
                                  });
                                },
                              }}
                              deliveryDialogProps={{
                                vendor: event.vendor,
                                vendorEventId: event.vendorEventId,
                                overrides: crawlerProperties.deliveryMethodOverrides,
                                onSave: (newOverrides) => {
                                  mutation.mutateAsync({
                                    deliveryMethods: newOverrides,
                                  });
                                },
                              }}
                            />
                          }
                        >
                          <Button icon={IconNames.SETTINGS}>Settings</Button>
                        </Popover>
                        {crawlerProperties.isEnabled && (
                          <PopoverConfirmDanger
                            isOpen={isDisablePopoverOpen}
                            onClose={() => setIsDisablePopoverOpen(false)}
                            titleText="Confirm Disable Event"
                            messageText="Are you sure you want to disable this event? Disabling the event will remove all inventory and stop all crawling."
                            confirmButtonLabel="Disable"
                            onConfirmClick={() =>
                              mutation.mutate({ isEnabled: false })
                            }
                          >
                            <Switch
                              label="Enabled"
                              large={true}
                              style={{ marginBottom: "0px" }}
                              checked={crawlerProperties.isEnabled}
                              onChange={() => setIsDisablePopoverOpen(true)}
                              disabled={mutation.isLoading}
                            />
                          </PopoverConfirmDanger>
                        )}
                        {!crawlerProperties.isEnabled && (
                          <Switch
                            label="Enabled"
                            large={true}
                            style={{ marginBottom: "0px" }}
                            checked={crawlerProperties.isEnabled}
                            onChange={() =>
                              mutation.mutate({ isEnabled: true })
                            }
                            disabled={mutation.isLoading}
                          />
                        )}
                      </>
                    )}
                  </Actions>
                </>
              )}
            </Composition>
          </Toolbar>
          <Grid>
            <ListingsGrid listings={listings} quickFilterText={filterText} />
          </Grid>
        </>
      )}
    </Composition>
  );
}
