import { IDateFormatProps } from "@blueprintjs/datetime";
import { format, parse, formatISO, subMinutes } from "date-fns";

export function getDateInputProps(desiredFormat: string): IDateFormatProps {
  // note that the native implementation of Date functions differs between browsers
  return {
    formatDate: (date) => format(date, desiredFormat),
    parseDate: (str) => parse(str, desiredFormat, new Date()),
  };
}

export function fromEventDate(date: string | Date) {
  // TODO: Add logic to ensure there's no timezone information in the string
  return new Date(date);
}

export function toEventDate(date: Date) {
  // Before being serialized, we must back-out the timezone difference
  return formatISO(subMinutes(date, date.getTimezoneOffset()));
}
