import { Button, Label, MenuItem } from "@blueprintjs/core";
import { Suggest, ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { IIdName } from "../../models/common/IIdName";

type SuggestType = IIdName | string;

export interface IIdNameSuggestProps<T extends SuggestType> {
  labelText?: string;
  placeholder?: string;
  items: T[];
  selectedItem: T | null;
  inputProps?: any;
  onChange?(selectedItem: T | null): void;
}

function isIdName(item: SuggestType): item is IIdName {
  return (item as IIdName).name !== undefined;
}

function renderInputValue(item: SuggestType) {
  return isIdName(item) ? item.name : item.toString();
}

function itemsEqual(itemA: SuggestType, itemB: SuggestType) {
  if (isIdName(itemA) && isIdName(itemB)) {
    return itemA.id === itemB.id;
  }

  return itemA.toString().toLowerCase() === itemB.toString().toLowerCase();
}

const filterItems: ItemPredicate<SuggestType> = (
  query,
  item,
  _index,
  exactMatch
) => {
  const normalizedQuery = query.toLowerCase();
  const normalizedItem = isIdName(item)
    ? item.name.toLowerCase()
    : item.toString().toLowerCase();

  if (exactMatch) {
    return normalizedItem === normalizedQuery;
  }

  return normalizedItem.indexOf(normalizedQuery) >= 0;
};

const renderItem: ItemRenderer<SuggestType> = (
  item,
  { handleClick, modifiers }
) => {
  if (isIdName(item)) {
    return (
      <MenuItem
        text={item.name}
        key={item.id}
        onClick={handleClick}
        active={modifiers.active}
      />
    );
  }

  return (
    <MenuItem
      text={item}
      key={item.toString()}
      onClick={handleClick}
      active={modifiers.active}
    />
  );
};

export function IdNameSuggest<T extends SuggestType>({
  selectedItem,
  ...props
}: IIdNameSuggestProps<T>) {
  const ItemSuggest = Suggest.ofType<T>();

  function handleItemSelect(item: T | null) {
    if (item === selectedItem) {
      return;
    }

    if (props.onChange) {
      props.onChange(item);
    }
  }

  function clearButton() {
    if (!selectedItem) {
      return undefined;
    }

    return (
      <Button
        minimal={true}
        icon="cross"
        onClick={() => handleItemSelect(null)}
      />
    );
  }

  if (props.labelText) {
    return (
      <Label>
        {props.labelText}
        <ItemSuggest
          items={props.items}
          itemRenderer={renderItem}
          inputValueRenderer={renderInputValue}
          itemsEqual={itemsEqual}
          itemPredicate={filterItems}
          onItemSelect={handleItemSelect}
          inputProps={{
            placeholder: props.placeholder,
            rightElement: clearButton(),
            ...props.inputProps,
          }}
          selectedItem={selectedItem}
          noResults={<MenuItem disabled={true} text="No results." />}
        />
      </Label>
    );
  }

  return (
    <ItemSuggest
      items={props.items}
      itemRenderer={renderItem}
      inputValueRenderer={renderInputValue}
      itemsEqual={itemsEqual}
      itemPredicate={filterItems}
      onItemSelect={handleItemSelect}
      inputProps={{
        placeholder: props.placeholder,
        rightElement: clearButton(),
      }}
      selectedItem={selectedItem}
      noResults={<MenuItem disabled={true} text="No results." />}
    />
  );
}
