import {
  Button,
  Checkbox,
  ControlGroup, HTMLSelect,
  InputGroup,
  Label, OptionProps,
} from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { addDays, format, startOfDay, subDays } from "date-fns";
import { useState } from "react";
import { IIdName } from "../../models/common/IIdName";
import { DateFilter } from "../shared/DateFilter";
import { IdNameSuggest } from "../shared/IdNameSuggest";
import type { ISearchEventsRequest } from "../../models/ISearchEventsRequest";
import { handleBooleanChange } from "../shared/HTMLInputElementHandlers";

const earliestDate = subDays(new Date(), 1);
const latestDate = addDays(new Date(), 365 * 3);

export interface FilterPanelProps {
  onFilter: (filters: ISearchEventsRequest) => void;
  performers?: IIdName[];
  venues?: IIdName[];
}

export function FilterPanel({
  onFilter,
  performers,
  venues,
}: FilterPanelProps) {
  const [searchText, setSearchText] = useState("");
  const [eventIdText, setEventIdText] = useState("");
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();
  const [performer, setPerformer] = useState<IIdName | null>(null);
  const [venue, setVenue] = useState<IIdName | null>(null);
  const [withTickets, setWithTickets] = useState<boolean>();
  const [vendor, setVendor] = useState<string>("");
  
  const vendorOptions: OptionProps[] = [
    { label: "All", value: "" },
    { label: "AXS", value: "AXS" },
    { label: "Evenue", value: "Evenue"},
    { label: "SeatGeek", value: "SeatGeek" },
    { label: "Ticketmaster", value: "Ticketmaster" }
  ];

  function onSearchClick() {
    onFilter({
      query: searchText === "" ? undefined : searchText,
      dateFrom: minDate ? format(startOfDay(minDate), "yyyy-MM-dd") : undefined,
      dateThru: maxDate ? format(startOfDay(maxDate), "yyyy-MM-dd") : undefined,
      performerId: performer ? performer.id : undefined,
      venueId: venue ? venue.id : undefined,
      vendor: vendor === "" ? undefined : vendor,
      vendorEventId: eventIdText === "" ? undefined : eventIdText,
      hasTickets: withTickets === true ? true : undefined,
    });
  }

  function onClearClick() {
    setSearchText("");
    setMinDate(undefined);
    setMaxDate(undefined);
    setPerformer(null);
    setVenue(null);
    setEventIdText("");
    setWithTickets(undefined);
  }

  const isFilterDirty =
    searchText ||
    minDate ||
    maxDate ||
    performer ||
    venue ||
    eventIdText ||
    withTickets === true;

  const areas = `
        search 
        dates        
        performer 
        venue 
        eventId
        vendor
        checks
        searchButton`;

  return (
    <Composition areas={areas} margin="1rem">
      {({ Search, Dates, Performer, Venue, EventId, Vendor, Checks, SearchButton }) => (
        <>
          <Search>
            <Label>
              Search
              <InputGroup
                type="search"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSearchClick();
                  }
                }}
              />
            </Label>
          </Search>
          <Dates>
            <ControlGroup>
              <DateFilter
                labelText="Start Date"
                placeholder="Start Date"
                minDate={earliestDate}
                maxDate={latestDate}
                value={minDate}
                onChange={(selectedDate) => {
                  setMinDate(selectedDate);
                  if (!maxDate) {
                    setMaxDate(selectedDate);
                  }
                }}
              />
              <DateFilter
                labelText="End Date"
                placeholder="End Date"
                minDate={earliestDate}
                maxDate={latestDate}
                value={maxDate}
                onChange={(selectedDate) => {
                  setMaxDate(selectedDate);
                  if (!minDate) {
                    setMinDate(selectedDate);
                  }
                }}
              />
            </ControlGroup>
          </Dates>
          <Performer>
            <IdNameSuggest
              labelText="Performer"
              placeholder="Performer"
              items={performers ?? []}
              selectedItem={performer}
              onChange={(p) => setPerformer(p)}
              inputProps={{ fill: true }}
            />
          </Performer>
          <Venue>
            <IdNameSuggest
              labelText="Venue"
              placeholder="Venue"
              items={venues ?? []}
              selectedItem={venue}
              onChange={(v) => setVenue(v)}
              inputProps={{ fill: true }}
            />
          </Venue>
          <EventId>
            <Label>
              Event Id
              <InputGroup
                type="text"
                fill={true}
                placeholder=""
                value={eventIdText}
                onChange={(e) => setEventIdText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSearchClick();
                  }
                }}
                rightElement={
                  eventIdText ? (
                    <Button
                      icon="cross"
                      minimal={true}
                      onClick={() => setEventIdText("")}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            </Label>
          </EventId>
          <Vendor>
            <Label>
              Vendor
              <HTMLSelect
                  options={vendorOptions}
                  value={vendor}
                  onChange={(e) => setVendor(e.target.value)}
              />
            </Label>
          </Vendor>
          <Checks>
            <Checkbox
              checked={withTickets === true}
              label="With Tickets"
              style={{ marginBottom: "15px" }}
              onChange={handleBooleanChange((checked) =>
                setWithTickets(!checked ? undefined : checked)
              )}
            />
          </Checks>
          <SearchButton>
            <Button icon="search" text="Search" onClick={onSearchClick} />
            {isFilterDirty && (
              <Button
                icon="cross"
                text="Reset"
                onClick={onClearClick}
                style={{ marginLeft: "8px" }}
              />
            )}
          </SearchButton>
        </>
      )}
    </Composition>
  );
}
